@font-face {
    font-family: tiltneon;
    src: url("./static/fonts/tiltneon.ttf");
}

@font-face {
    font-family: minipixel7;
    src: url("./static/fonts/mini-pixel-7.ttf");
}

@font-face {
    font-family: rubik;
    src: url("./static/fonts/Rubik-VariableFont_wght.ttf");
}

@font-face {
    font-family: barlow;
    src: url("./static/fonts/Barlow-Medium.ttf");
}

.App {
  text-align: center;
  display: flex;
  height: 100vh;
  flex-direction: column;

  font-family: barlow;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}


.App-header2 {
    /* background-color: #282c34; */
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 3em;
    font-size: calc(10px + 2vmin);
    flex-grow: 1;
    /* color: white; */
  }
  
.App-link {
  color: #61dafb;
}

.minipixel7 {
    font-family: minipixel7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**/
.modal-form {
    text-align: left;
}

/* Test styles */
.redbox {
    border: 1px solid red;
}

.cta-container {
    max-width: 80%;
    text-align: left;
}


.nav-bar-button {
    /* background-color: #efefef !important; */
    color: #1a1a1a !important;
    font-family: barlow !important;
    font-size: 0.8em !important;
    text-transform: capitalize !important;
    padding-left: 1.2em !important;
    padding-right: 1.2em !important;
    margin-left: 1em !important;
    border: 0 !important;
    border-radius: 0;
}

.nav-bar-button:hover {
    border: 1px solid #1a1a1a;
    text-decoration: underline !important;
}

.sign-in-button, .morph-step-button {
    background-color: #1a1a1a !important;
    margin-right: 10px !important;
}

.copy, .sign-in-loading, .sign-in-button, .try-it-out-button, .morph-step-button, .morph-step-button-outlined {
    font-family: barlow !important;
    border-radius: 0 !important;
}

.copy, .try-it-out-button, .morph-step-button-outlined {
    color: #1a1a1a !important;
    border: 1px solid #1a1a1a !important
}

.copy {
    margin-right: 1em !important;
}

.morph-step-button-outlined:hover {
    opacity: 0.7;
}

.logo {
    font-family: minipixel7;
    cursor: pointer;
}

.big {
    font-size: 3.6em;
}

.cta-container > h5 {
    display: block;
    width: 60%;
}

.welcome {
    text-align: left;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
}

.appbar {
    text-align: center;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    background-color: white !important;
    color: #1a1a1a !important;
    box-shadow: none !important;
    width: 92% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0;
    padding-top: 1em !important;
    padding-bottom: 0.5em !important;
    border-bottom: 1px solid lightgray;
}

.w90 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.p2s {
    padding-left: 2em;
    padding-right: 2em;
}


.trial-option-button, .home-nav-button {
    border: 2px solid #1a1a1a;
    /* border-radius: 10px; */
    min-height: 200px;
    cursor: pointer;
    display: flex;
    justify-content:flex-end;
    align-items: flex-start;
    padding: 1em;
    transition: background-color 0.5s;
    flex-direction: column;
}

.red {
    color: red;
    margin-left: 1em;
}

.red:hover {
    opacity: 0.6;
}


.trial-option-button:hover {
    opacity: 0.7;
}

.trial-option-button-selected {
    background-color: #1a1a1a;
    color: white;
}

.home-nav-button:hover {
    /* border: 2px solid #cfcfcf; */
    background-color: #1a1a1a;
    color: white;
}

.home-nav-button-title {
    font-family: minipixel7;
    font-size: 2em;
}

.home-nav-button-description {
    text-align: left;
    font-size: 0.9em;
}

.work-select-container {
}

.select-label-company, .work-ex-card-company {
    font-family: minipixel7;
    font-size: 1.2em;
}

.select-label-designation {
    font-family: barlow;
    /* font-size: 1.2em; */
}

.work-ex-card-company, .work-ex-card-designation {
    text-transform: capitalize;
}

.work-ex-card-date, .work-ex-card-description {
    font-size: 0.8em;
    opacity: 0.9;
}

.work-ex-card-description {
    margin-top: 1em;
}

.work-select-input {
    display: flex;
    flex-direction: row;
    border: 1px solid #cfcfcf;
    padding: 1em;
    /* border-radius: 5px; */
    margin-bottom: 1em;
    cursor: pointer;
}

.work-select-input:hover, .work-select-input-selected {
    border: 1px solid #1a1a1a;
}

.work-select-input-selected > .select-indicator {
    background-color: #1a1a1a;
}

.select-indicator {
    width: 10%;
    max-width: 20px;
    border: 1px solid #cfcfcf;
    background-color: #cfcfcf;
    margin-right: 1em;
    /* border-radius: 5px; */
}

.select-label {
    text-transform: capitalize;
    text-align: left;
}

.original-work-preview {
    text-align: left;
}

.original-work-preview > h6 {
    font-weight: bold !important;
}

.result-preview {
    text-align: left;
}

.MuiStepLabel-label {
    font-family: barlow !important;
}

svg.Mui-active, svg.Mui-completed {
    color: #1a1a1a !important;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
}

.jd-form {
    margin-bottom: 1em !important;
    width: 100%;
}

.section-title {
    font-family: minipixel7;
}

.section-subtitle {
    width: 50%;
    font-size: 0.8em;
}

.step-indicator {
    border: 1px solid #afafaf;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5em;
    font-size: 0.8em;
}

.step-indicator > div {
    margin-right: 0.6em;
    color: #9f9f9f;
}

.loading-message {
    text-align: left;
}

.loading-message > h3 {
    font-family: minipixel7;
}

.profile-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-view-bar {
    max-width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8em;
}

.left-text {
    text-align: left;
}

.profile-view-select-button {
    border: 1px solid #1a1a1a;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.6em;
    padding-right: 0.6em;
    min-width: 80px;
    margin-right: 1em;
    cursor: pointer;
}

.profile-view-select-button-selected {
     color: white;
     background-color: #1a1a1a;
}

.profile-content {
    text-align: left;
}

.work-ex-card {
    border: 1px solid #cfcfcf;
    padding: 1em;
    margin-bottom: 1em;
    cursor: pointer;
    text-align: left;
}

.work-ex-card-active {
    border: 1px solid #9f9f9f;
}

.skills-tag {
    font-size: 0.9em;
    display: inline-block;
    color: white;
    background-color: #1a1a1a;
    padding: 0.2em 1em 0.2em 1em;
    min-width: 40px;
    margin-bottom: 0.4em;
    margin-right: 0.4em;
    cursor: pointer;
}

.checkbox-div {
    text-align: left !important;
}

.no-work {
    border: 1px solid #dfdfdf;
    min-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
}


.select-container {
    margin-bottom: 1em !important;
}

.review-label {
    font-weight: bold;
    margin-bottom: 0.4em;
}

.step-message {
    font-size: 0.8em;
    font-weight: bold;
    color: #9f9f9f;
}

.red {
    color: red;
    font-weight: bold;
}

.green {
    color: green;
    font-weight: bold;
}

.yellow {
    color: goldenrod;
    font-weight: bold;
}

@media (max-width: 800px) {
    .cta-container > h5 {
        display: block;
        width: 100%;
    }

}

.profile-progress {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10px;
    margin-top: 0.8em;
}

.profile-progress > div {
    margin-right: 0.5em;
    width: 33%;
    height: 2px;
}

.bg-black1 {
    background-color: seagreen;
}

.bg-gray1 {
    background-color: #ccc;
}

.bar-logo {
    font-size: 1.5em;
}

.remove-button {
    color: red;
    font-size: 0.8em;
}

.remove-button:hover {
    text-decoration: underline;
}

.hidden-input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
}

.file-upload-input {
    background-color: white !important;
    border-radius: 0 !important;
    font-family: barlow !important;
    border: 2px dashed #1a1a1a !important;
    color: #1a1a1a !important;
}

.file-upload-container {
    display: flex;
    flex-direction: row;
}

.file-submit-button {
    border: 2px solid #1a1a1a !important;
    font-family: barlow !important;
    margin-left: 1em !important;
    /* background-color: white !important; */
    border-radius: 0 !important;
    background-color: #1a1a1a !important;
}